interface Args {
  template: string
  map: Record<any, any>
}

const get = (path: string, obj: any) => {
  return path.split('.').reduce((res, key) => res[key] || '', obj)
}

export const smsTemplateParser = ({ template, map }: Args): string => {
  return template?.replace(/\{.+?}/g, (match) => {
    const path = match?.substr(1, match.length - 2).trim()

    return get(path, map)
  })
}
